
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: "RedirectPage",
})
// 公共重定向页面，以前是在企业首页处理；
// 功能是想拿老的url，使用新页面以iframe的形式打开
export default class RedirectPage extends Vue {
    private localPushUrl = [    // 本地跳转地址，不走iframe
        '/company/myOrder',
    ];

    private created() {
        if (this.$route.fullPath.indexOf('?url=') > -1) {
            console.log('Redirect fullPath', this.$route.fullPath);
            let urlParam = this.$route.fullPath.split('?url=')[1];
            urlParam = decodeURIComponent(urlParam);
            if ( urlParam.indexOf('?') === -1) {
                urlParam = urlParam.replace('&', '?');
            }
            const urlArr = urlParam.split('?');
            console.log('Redirect urlArr[0]', urlArr[0]);
            console.log('Redirect unescape(urlArr[0])', unescape(urlArr[0]));
            console.log('Redirect urlArr[1]', urlArr[1]);
            let newUserParam = '';
            if (urlArr[1] && urlArr[1] !== null && urlArr[1] !== "") {
                urlArr[1].split('&').forEach((item: any, index: number) => {
                    const itemSplit = item.split('=');
                    if (itemSplit.length === 2) {
                        if (index !== 0) {
                            newUserParam += '&';
                        }
                        newUserParam += itemSplit[0] + '=' + encodeURIComponent(itemSplit[1]);
                    }
                });
            }
            console.log('Redirect newUserParam', newUserParam);
            if (this.localPushUrl.includes(unescape(urlArr[0]))) {
                this.$router.push(urlParam).catch((err) => err);
            } else {
                this.$router.push({
                    path: `/company/redirectIframe?url=${unescape(urlArr[0])}&data=${newUserParam}`,
                    query: { url: unescape(urlArr[0]), data: newUserParam}}).catch((err) => err);
            }
        } else {
            this.$router.push('/index').catch((err) => err);
        }
    }
}
