
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import { ElForm } from 'element-ui/types/form';
import { formatTime } from '@/utils/utils';

@Component({
    name: "MessageCenter",
    components: {
        ContentTitle,
    },
})
export default class MessageCenter extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private formatTime: any = formatTime;
    private contentTitle: string = "消息中心";
    private total: number = 0;
    private messageData: any = {
        startTime: '',
        ennTime: '',
        status: '',
        keyword: '',
        pageNo: 1,
        pageSize: 10,
    };
    private messageList: any = {};
    private created() {
        // this.getMessageList();
    }
    private searchHandle(formName: any) {
        this.getMessageList();
    }
    private getMessageList() {
        const messageTab = Object.assign({}, this.messageData);
        messageTab.status = messageTab.status !== '' ? Number(messageTab.status) : -1;
        // this.messageData.status = this.messageData.status !== '' ? Number(this.messageData.status) : -1;
        this.$httpService.getData(messageTab, '/apiProxy/api/frontend/users/messages')
        .then((res: any) => {
            this.messageList = res.list;
            this.total = res.total;
        });
    }
    private resetHandle(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private setReadMessage() {
        this.$httpService.postData({}, '/apiProxy/api/frontend/users/messages/read-status').then((res: any) => {
            this.getMessageList();
        });
    }
    private currentChange(e: any) {
        this.messageData.pageNo = e;
        this.getMessageList();
    }
    // private formatTime(timestamp: number | string, format: string = "yyyy-MM-dd hh:mm:ss"): string {
    //     const date = new Date(timestamp);
    //     const dateFormat: any = {
    //         "y+": date.getFullYear(),
    //         "M+": date.getMonth() + 1,
    //         "d+": date.getDate(), // 日
    //         "h+": date.getHours(), // 小时
    //         "m+": date.getMinutes(), // 分
    //         "s+": date.getSeconds(), // 秒
    //     };

    //     if (/(y+)/i.test(format)) {
    //         format = format.replace(RegExp.$1, (dateFormat["y+"] + "").substr(4 - RegExp.$1.length));
    //     }
    //     for (const key in dateFormat) {
    //         if (new RegExp("(" + key + ")").test(format)) {
    //             format = format.replace(
    //                 RegExp.$1, RegExp.$1.length === 1 ?
    //                 dateFormat[key] :
    //                 ("00" + dateFormat[key]).substr(("" + dateFormat[key]).length));
    //         }
    //     }
    //     return format;
    // }
}
