
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import MyCard from "../../../person/home/component/MyCard.vue";
import OpinionDialog from "@/components/RetainInfoDialog/OpinionDialog.vue";
@Component({
    name: "SystemList",
    components: {
        MyCard,
        OpinionDialog,
    },
})
export default class SystemList extends Vue {
    @Prop(Number) private certStatus!: number;
    @Prop(Number) private screenWidth!: number;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private proList: any = [];
    private systemList: any = [];
    private switchCompanyId = '';   // 切换企业的id
    private hotProduct: any = [];  // 热门产品
    private opinionVisible: boolean = false;
    @Emit('buyService')
    private buyService(detailUrl: any) {
        return detailUrl;
    }
    // 续费
    @Emit('renewal')
    private renewal(row: any) {
        return row;
    }
    private checkDetail(row: any) {
        if (row.goodsStatus !== 2) {
            this.$message.warning('商品已下架!');
            return;
        }
        this.buyService(row.detailUrl);
    }
    private created() {
        this.initData();
    }
    // 初始化加载数据
    private initData() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/systems/grouped-purchased').then((res: any) => {
            this.systemList = res.map((system: any) => ({
                ...system,
                showMore: false,
            }));
            // 反馈弹窗传入的应用列表
            // this.proList = [];
            // res.map((system: any) => {
            //     if (system.products) {
            //         system.products.map((product: any) => {
            //             this.proList.push({
            //                 id: product.mcId,
            //                 name: product.name,
            //             });
            //         });
            //     }
            // });
        });
    }
    // 建议反馈
    private openOpinion() {
        this.opinionVisible = true;
    }
    private closeOpinion(num: number) {
        this.opinionVisible = false;
    }
    // 跳转链接
    private async openUrl(status: number, url: string) {
        if (status === -1) {
            this.$message.warning('服务器部署中!');
            return;
        }
        if (await this.$isLogin()) {
            window.open(url);
        } else {
            this.$alert('登录状态已过期，请重新登录', '提示', {
                showClose: false,
                confirmButtonText: '重新登录',
                callback: (action: any) => {
                    // 清除各种缓存数据
                    localStorage.clear();
                    // 跳转到登录页面
                    this.$router.push("/login").catch((err) => err);
                },
            });
        }
    }
    // 前往购买产品
    private goMall() {
        this.$router.push({ path: "/company/resource/resourceBuy" }).catch((err) => err);
    }
    // 添加应用
    private addSystem() {
        // 实名认证校验
        if (this.certStatus !== 2) {
            // 未认证
            let msg = "尊敬的用户，为保障您的账户安全，请您完成实名认证。";
            let confirmText = "去认证";
            if (this.certStatus === 1) {    // 审核中
                msg = "您当前提交的实名认证正在审核中，预计将1-3个工作日完成。";
                confirmText = "认证审核中";
            } else if (this.certStatus === -1) {    // 认证不通过
                msg = "您当前提交的实名认证未通过审核，请重新提交申请。";
                confirmText = "重新提交";
            }
            this.$confirm(msg, '提示', {
                confirmButtonText: confirmText,
                showCancelButton: false,
            }).then(() => {
                this.$router.push({ path: "/company/Certification",
                query: { isBack: '0', isCert: '1' }}).catch((err) => err);
            });
        } else {
            this.$router.push({ path: "/company/resource/resourceBuy",
            query: { from: 'home' }}).catch((err) => err);
        }
    }
}
