
import '@/assets/css/iframeStyle.scss';
import { Component, Emit, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import ServiceDialog from "../../resourceManage/buy/component/ServiceDialog.vue";
import ServiceItem from "../../resourceManage/buy/component/ServiceItem.vue";

@Component({
    name: "OrderConfirm",
    components: {
        ContentTitle,
        ServiceItem,
        ServiceDialog,
    },
})
export default class OrderConfirm extends Vue {
    private contentTitle: string = "购买服务";
    private serviceFlag: boolean = false;
    private serviceList: any = [];
    private serviceItemData: any = {};

    private async created() {
        await this.getServiceList();
    }
    private async getServiceList() {
        // 得到服务商品列表
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/service')
        .then((res: any) => {
            this.serviceList = [];
            res.filter((item: any) => {
                item.serverNum = 1;
                this.serviceList.push(item);
            });
        });
    }
    // 接收子页面传回来的值
    private propGoBack() {
        // window.location.href = '/web/company/companyHome?activePage=1';
        this.returnClickTodo();
    }
    // 给父组件传值
    @Emit('returnClick')
    private returnClickTodo(): any {
        return true;
    }
    private propBuyService(value: object) {
        this.serviceItemData = value;
        this.serviceFlag = true;
    }
    private propCloseMsg(value: any) {
        this.serviceFlag = false;
        if (!value.id) {
            return;
        }
        const itemData: any = {
            appName: value.name,
            goodsId: value.id, // 选中商品传给后端的值
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: value.serverNum, // 时长
            orderGoodsSceneType: 24,
        };
        const goodsList: any = [];
        goodsList.push(itemData);
        this.$router.push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsList)}`).catch((err) => err);
    }
}
