
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import { fromCode, toCode } from '@/utils/encrypt';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: 'RenewOrUpgrade',
    components: {
        ContentTitle,
    },
})
export default class RenewOrUpgrade extends Vue {
    private loading: boolean = false;
    private payType: any = '1'; // 1，服务器 续费；2：服务器 升级；3，数据库 续费；4，数据库 升级
    private contentTitle: string = "服务器";
    // 数据库的cpu
    private dbCpuSetList: any = [];
    private dbFreeCpu: number = 0;
    private dbCpu: number = 0;
    // 数据库的memory
    private dbMemorySetList: any = [];
    private dbFreeMemory: number = 0;
    private dbMemory: number = 0;
    // 数据库的HardDisk
    private dbHardDiskSetList: any = [];
    private dbFreeHardDisk: number = 0;
    private dbHardDisk: number = 0;
    // 数据库的timeNumber
    private dbTimeNumberSetList: any = [];
    private dbFreeTimeNumber: number = 0;
    private dbTimeNumber: number = 0;
    // 服务器的cpu
    private serverCpuSetList: any = [];
    private serverFreeCpu: number = 0;
    private serverCpu: number = 0;
    // 服务器的memory
    private serverMemorySetList: any = [];
    private serverFreeMemory: number = 0;
    private serverMemory: number = 0;
    // 服务器的HardDisk
    private serverHardDiskSetList: any = [];
    private serverFreeHardDisk: number = 0;
    private serverHardDisk: number = 0;
    // 服务器的timeNumber
    private serverTimeNumberSetList: any = [];
    private serverFreeTimeNumber: number = 0;
    private serverTimeNumber: number = 1;
    // url带的userId
    private userId: string = '';
    private serverId: string = '';
    private serverTab: any = {
        serverCpu: 0,
        serverMemory: 0,
        serverHardDisk: 0,
    };
    private dbId: string = '';
    private dbTab: any = {
        serverId: 0,
        dbCpu: 0,
        dbMemory: 0,
        dbHardDisk: 0,
    };
    // 所有商品价格
    private allProductTab: any = {
        dbDiscountMoney: 0, // 数据库优惠
        dbMoney: 0, // 数据库价格
        serverDiscountMoney: 0, // 服务器优惠
        serverMoney: 0, // 服务器价格
    };
    private propGoBlack() {
        this.$router.back();
    }
    get getDBDiscountPrice() {
        return parseFloat(this.allProductTab.dbMoney).toFixed(2);
    }
    get getServerDiscountPrice() {
        return parseFloat(this.allProductTab.serverMoney).toFixed(2);
    }
    private created() {
        if (this.$route.fullPath.indexOf('userData=') > -1) {
            const userId = this.$route.query.userData + '';
            this.userId = fromCode(userId);
        }
        if (this.$route.fullPath.indexOf('payType=') > -1) {
            this.payType = this.$route.query.payType + '';
        } else {
            this.payType = '1';
        }
        if (this.payType === '1' || this.payType === '2') {
            this.contentTitle = '服务器';
            if (this.$route.fullPath.indexOf('serverId=') > -1) {
                this.serverId = this.$route.query.serverId + '';
            } else {
                this.$message.warning('数据有误');
                return;
            }
        } else if (this.payType === '3' || this.payType === '4') {
            this.contentTitle = '数据库';
            if (this.$route.fullPath.indexOf('dbId=') > -1) {
                this.dbId = this.$route.query.dbId + '';
            } else {
                this.$message.warning('数据有误');
                return;
            }
        }
        const setInfo = localStorage.getItem('set_info_api');
        if (setInfo === null || setInfo === '' || setInfo === undefined) {
            this.getSetInfo();
        } else {
            this.init();
        }
        this.getCompanyServer().then(() => {
            this.getPrice();
        });
    }
    // 获得个人的 服务器列表
    private  getCompanyServer() {
        return new Promise((resolve, reject) => {
            this.loading = true;
            const paramUserId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
            this.$httpService.getData({preventRepeat: 1}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
            .then((res: any) => {
                res.serverInfoList.forEach((item: any) => {
                    if (Number(item.serverId) === Number(this.serverId)) {
                        this.serverCpu = item.serverCpu;
                        this.serverMemory = item.serverMemory;
                        this.serverHardDisk = item.serverHarddisk;
                        this.serverTab = {
                            serverCpu: item.serverCpu,
                            serverMemory: item.serverMemory,
                            serverHardDisk: item.serverHarddisk,
                        };
                    }
                });
                resolve(res);
            }).catch(() => {
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            });
        });
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            this.init();
        });
    }
    private init() {
        this.getServerInfo();
    }
    private getServerInfo() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
         // 配置数据库的Cpu
        this.dbCpuSetList = [];
        this.dbFreeCpu =
            res.dbFreeSet.cpu > 0 ? res.dbFreeSet.cpu : res.dbCpuSetList[0].number;
        this.dbCpu = this.dbFreeCpu;
        res.dbCpuSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeCpu) {
                this.dbCpuSetList.push(item);
            // }
        });
        // 配置数据库的Memory
        this.dbMemorySetList = [];
        this.dbFreeMemory =
            res.dbFreeSet.memory > 0 ? res.dbFreeSet.memory : res.dbMemorySetList[0].number;
        this.dbMemory = this.dbFreeMemory;
        res.dbMemorySetList.filter((item: any) => {
            // if (item.number >= this.dbFreeMemory) {
                this.dbMemorySetList.push(item);
            // }
        });
        // 配置数据库的HardDisk
        this.dbHardDiskSetList = [];
        this.dbFreeHardDisk =
            res.dbFreeSet.hardDisk > 0 ? res.dbFreeSet.hardDisk : res.dbHardDiskSetList[0].number;
        this.dbHardDisk = this.dbFreeHardDisk;
        res.dbHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeHardDisk) {
                this.dbHardDiskSetList.push(item);
            // }
        });
        // 配置数据库的TimeNumber
        this.dbTimeNumberSetList = [];
        this.dbFreeTimeNumber =
            res.dbFreeSet.freeTimeNumber > 0 ? res.dbFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        this.dbTimeNumber = this.dbFreeTimeNumber;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeTimeNumber) {
            this.dbTimeNumberSetList.push(item);
            // }
        });
        // 配置服务器的TimeNumber
        this.serverTimeNumberSetList = [];
        this.serverFreeTimeNumber =
            res.serverFreeSet.freeTimeNumber > 0 ?
                res.serverFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeTimeNumber) {
            this.serverTimeNumberSetList.push(item);
            // }
        });
        // 配置服务器的Cpu
        this.serverCpuSetList = [];
        this.serverFreeCpu =
            res.serverFreeSet.cpu > 0 ? res.serverFreeSet.cpu : res.serverCpuSetList[0].number;
        // this.serverCpu = this.serverFreeCpu;
        res.serverCpuSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeCpu) {
            this.serverCpuSetList.push(item);
            // }
        });
        // 配置服务器的Memory
        this.serverMemorySetList = [];
        this.serverFreeMemory =
            res.serverFreeSet.memory > 0 ? res.serverFreeSet.memory : res.serverMemorySetList[0].number;
        // this.serverMemory = this.serverFreeMemory;
        res.serverMemorySetList.filter((item: any) => {
            // if (item.number >= this.serverFreeMemory) {
            this.serverMemorySetList.push(item);
            // }
        });
        // 配置服务器的HardDisk
        this.serverHardDiskSetList = [];
        this.serverFreeHardDisk =
            res.serverFreeSet.hardDisk > 0 ? res.serverFreeSet.hardDisk : res.serverHardDiskSetList[0].number;
        // this.serverHardDisk = this.serverFreeHardDisk;
        res.serverHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeHardDisk) {
            this.serverHardDiskSetList.push(item);
            // }
        });
    }
    private assembleGoodsList(): any {
        const goodsList: any = [];
        if (this.payType === '1') {
            // 服务器 续费
            // cpu: "10"
            // goodsId: 1
            // harddisk: "1024"
            // memory: "16"
            // orderDetailNumber: 1
            // orderDetailTimeNumber: 4
            // orderGoodsSceneType: 2
            // serverId: 772
            const itemData: any = {
                goodsId: RESOURCE_CONFIG.SERVER_GOODSID,
                appName: '云服务器',
                cpu: this.serverCpu,
                memory: this.serverMemory,
                harddisk: this.serverHardDisk,
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.serverTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW,
                serverId: this.serverId,
            };
            goodsList.push(itemData);
        } else if (this.payType === '2') {
            // 服务器 升级
            // cpu: "4"
            // goodsId: 1
            // harddisk: "200"
            // memory: "6"
            // orderDetailNumber: 1
            // orderDetailTimeNumber: 15
            // orderGoodsSceneType: 3
            // serverId: 1501
            const itemData: any = {
                goodsId: RESOURCE_CONFIG.SERVER_GOODSID,
                appName: '云服务器',
                cpu: this.serverCpu,
                memory: this.serverMemory,
                harddisk: this.serverHardDisk,
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: 15, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE,
                serverId: this.serverId,
            };
            goodsList.push(itemData);
        } else if (this.payType === '3') {
            // 数据库 续费
            // cpu: "2"
            // dbId: "3350"
            // goodsId: 3
            // harddisk: "30"
            // memory: "1"
            // orderDetailNumber: 1
            // orderDetailTimeNumber: 2
            // orderGoodsSceneType: 5
            // serverId: 1194
            const itemData: any = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID,
                appName: 'Oracle',
                cpu: this.dbCpu,
                memory: this.dbMemory,
                harddisk: this.dbHardDisk,
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.dbTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW,
                serverId: this.dbTab.serverId,
                dbId: this.dbId,
            };
            goodsList.push(itemData);
        } else if (this.payType === '4') {
            // 数据库 升级
            // cpu: "2"
            // dbId: "3350"
            // goodsId: 3
            // harddisk: "30"
            // memory: "3"
            // orderDetailNumber: 1
            // orderDetailTimeNumber: 24
            // orderGoodsSceneType: 6
            // serverId: 1194
            const itemData: any = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID,
                appName: 'Oracle',
                cpu: this.dbCpu,
                memory: this.dbMemory,
                harddisk: this.dbHardDisk,
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: 24, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_UPGRADE,
                serverId: this.dbTab.serverId,
                dbId: this.dbId,
            };
            goodsList.push(itemData);
        }
        return goodsList;
    }
    private getPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const goodsListArr = this.assembleGoodsList();
        if (goodsListArr.length === 0) {
            return;
        }
        paramData.goodsList = [].concat(goodsListArr);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck	= false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.allProductTab = {
                dbDiscountMoney: res.dbDiscountMoney, // 数据库优惠
                dbMoney: res.dbMoney, // 数据库价格
                serverDiscountMoney: res.serverDiscountMoney, // 服务器优惠
                serverMoney: res.serverMoney, // 服务器价格
            };
        });
    }
    private clickTimeNumber(num: number) {
        if  (this.serverTimeNumber !== num) {
            this.serverTimeNumber = num;
            this.getPrice();
        }
    }
    private clickDBTimeNumber(num: number) {
        if  (this.dbTimeNumber !== num) {
            this.dbTimeNumber = num;
            this.getPrice();
        }
    }
    private submitPay() {
        if (this.payType === '2') {
            const serverCpuNumMax =
                Math.max.apply(Math, this.serverCpuSetList.map((item: any) => item.number ));
            const serverMemoryNumMax =
                Math.max.apply(Math, this.serverMemorySetList.map((item: any) => item.number ));
            const serverHardDiskNumMax =
                Math.max.apply(Math, this.serverHardDiskSetList.map((item: any) => item.number ));
            if (serverCpuNumMax === this.serverTab.serverCpu
            && serverMemoryNumMax === this.serverTab.serverMemory
            && serverHardDiskNumMax === this.serverTab.serverHardDisk) {
                this.$alert(`您当前资源已达最高配置选项，如果需要更高的配置请联系平台客服人员为您处理，我们将竭诚为您服务`, '提示', {
                    showClose: false,
                    confirmButtonText: '我知道了',
                    callback: (action) => {
                        // ...
                    },
                });
                return;
            }
            if (this.allProductTab.serverMoney <= 0) {
                this.$message.warning('请选择要升级的配置');
                return;
            }
        } else if (this.payType === '4') {
            const dbCpuNumMax =
                Math.max.apply(Math, this.dbCpuSetList.map((item: any) => item.number ));
            const dbMemoryNumMax =
                Math.max.apply(Math, this.dbMemorySetList.map((item: any) => item.number ));
            const dbHardDiskNumMax =
                Math.max.apply(Math, this.dbHardDiskSetList.map((item: any) => item.number ));
            if (dbCpuNumMax === this.dbTab.dbCpu
            && dbMemoryNumMax === this.dbTab.dbMemory
            && dbHardDiskNumMax === this.dbTab.dbHardDisk) {
                this.$alert(`您当前资源已达最高配置选项，如果需要更高的配置请联系平台客服人员为您处理，我们将竭诚为您服务`, '提示', {
                    showClose: false,
                    confirmButtonText: '我知道了',
                    callback: (action) => {
                        // ...
                    },
                });
                return;
            }
            if (this.allProductTab.dbMoney <= 0) {
                this.$message.warning('请选择要升级的配置');
                return;
            }
        }
        const goodsList: any = this.assembleGoodsList();
        this.$router
        .push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsList)}&userId=${this.userId}`)
        .catch((err) => err);
    }
}
