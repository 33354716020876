
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import MyCard from "../../person/home/component/MyCard.vue";
import Proposal from "./component/Proposal.vue";
import BuyService from "./component/BuyService.vue";
import InviteStaff from "./component/InviteStaffDialog.vue";
import SystemList from "./component/SystemList.vue";    // 我的系统列表
import CarouselCard from "../../person/home/component/CarouselCard.vue";    // 轮播图
import ExperienceList from "./component/ExperienceList.vue";    // 体验/试用
import HotProductCard from "../../person/home/component/HotProductCard.vue";    // 热门推荐
import HelpCard from "../../person/home/component/HelpCard.vue";    // 使用帮助
import ServiceCard from "./component/ServiceCard.vue";    // 体验/试用
import RenewalList from "./component/RenewalList.vue";    // 待续费产品
import RenewalDialog from "./component/RenewalDialog.vue";    // 续费弹窗
import Certification from "../certification/index.vue";

@Component({
    name: "CompanyHome",
    components: {
        MyCard,
        Proposal,
        BuyService,
        InviteStaff,
        SystemList,
        CarouselCard,
        ExperienceList,
        HotProductCard,
        HelpCard,
        ServiceCard,
        RenewalList,
        RenewalDialog,
        Certification,
    },
})
export default class CompanyHome extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private iframUrl = '';
    // 配置页面
    private activePage: string = '1';
    // Proposal start
    private proposalFlag: boolean = false;
    private optionsData: any = [];
    // end
    // InviteStaff
    private inviteStaffFlag: boolean = false;
    // end
    // 续费
    private renewalFlag: boolean = false;
    private renewalProdInfo: any = {};
    // 续费列表
    private renewalListFlag: boolean = false;
    private throughData: any = [
        {
            num: '0',
            label: 'appNum',
            text: '我的系统',
            onClick: () => {
                return;
            },
        },
        {
            num: '0',
            label: 'trialNum',
            text: '我的试用',
            onClick: () => {
                return;
            },
        },
        {
            num: '0',
            label: 'experienceNum',
            text: '我的体验',
            onClick: () => {
                return;
            },
        },
        {
            num: '0',
            label: 'unpayNum',
            text: '待支付订单',
            onClick: () => {
                this.$router.push({ path: "/company/myOrder", query: {orderStatus: '0'}}).catch((err) => err);
            },
        },
        {
            num: '0',
            label: 'staffNum',
            text: '员工数量',
            onClick: () => {
                this.$router.push({ path: "/company/staff"}).catch((err) => err);
            },
        },
        {
            num: '0',
            label: 'msgNum',
            text: '未读消息',
            onClick: () => {
                this.$router.push({ path: "/company/message"}).catch((err) => err);
            },
        },
    ];
    private localPushUrl = [    // 本地跳转地址，不走iframe
        '/company/myOrder',
    ];
    private isBindEmail: boolean = false;
    private companyInfo: any = {};
    private screenWidth: any = null;
    private mounted() {
        this.screenWidth = window.screen.width || document.body.clientWidth || document.body.clientWidth;
        window.onresize = () => {
            return (() => {
                this.screenWidth = window.screen.width || document.body.clientWidth || document.body.clientWidth;
            })();
        };
    }
    private created() {
        if (this.$route.fullPath.indexOf('?url=') > -1) {
            console.log('this.$route.fullPath', this.$route.fullPath);
            let urlParam = this.$route.fullPath.split('?url=')[1];
            urlParam = decodeURIComponent(urlParam);
            if ( urlParam.indexOf('?') === -1) {
                urlParam = urlParam.replace('&', '?');
            }
            const urlArr = urlParam.split('?');
            console.log('urlArr[0]', urlArr[0]);
            console.log('unescape(urlArr[0])', unescape(urlArr[0]));
            console.log('urlArr[1]', urlArr[1]);
            let newUserParam = '';
            if (urlArr[1] && urlArr[1] !== null && urlArr[1] !== "") {
                urlArr[1].split('&').forEach((item: any, index: number) => {
                    const itemSplit = item.split('=');
                    if (itemSplit.length === 2) {
                        if (index !== 0) {
                            newUserParam += '&';
                        }
                        newUserParam += itemSplit[0] + '=' + encodeURIComponent(itemSplit[1]);
                    }
                });
            }
            console.log('newUserParam', newUserParam);
            if (this.localPushUrl.includes(unescape(urlArr[0]))) {
                this.$router.push(urlParam).catch((err) => err);
            } else {
                // this.$router.push({
                //     name: "RedirectIframe",
                //     params: { url: unescape(urlArr[0]), data: newUserParam}}).catch((err) => err);
                this.$router.push({
                    path: `/company/redirectIframe?url=${unescape(urlArr[0])}&data=${newUserParam}`,
                    query: { url: unescape(urlArr[0]), data: newUserParam}}).catch((err) => err);
            }
        }
        if (this.$route.fullPath.indexOf('activePage=') > -1) {
            this.activePage = this.$route.query.activePage + '';
        }
        this.$nextTick(() => {
            if (localStorage.getItem("userEmail") && localStorage.getItem("userEmail") !== '') {
                this.isBindEmail = false;
            } else {
                this.isBindEmail = true;
            }
        });
        this.getCompanyInfo();
    }
    private getCompanyInfo() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/info/profile')
        .then((res: any) => {
            this.companyInfo = res;
        });
    }
    private propReturn() {
        this.activePage = '1';
    }
    private ProposalCloseMsg() {
        this.proposalFlag = false;
    }
    private bindEmail() {
        this.$router.push({ path: "/company/security"}).catch((err) => err);
    }
    private closeBindTips() {
        localStorage.setItem("userEmail", 'none');
        this.isBindEmail = false;
    }
    private clickInvite() {
        this.inviteStaffFlag = true;
    }
    private propInviteStaff() {
        this.inviteStaffFlag = false;
    }
    // 打开续费弹窗
    private renewal(row: any) {
        this.renewalProdInfo = row;
        this.renewalFlag = true;
    }
    // 关闭续费弹窗
    private renewalClose() {
        this.renewalFlag = false;
    }
    // 显示待续费产品弹窗
    private showRenewal() {
        this.renewalListFlag = true;
    }
    // 关闭待续费产品弹窗
    private hideRenewal() {
        this.renewalListFlag = false;
    }
    // 切换页面，显示iframe内容
    private showIframePage(url: string) {
        this.iframUrl = `${url}?pageSource=new`;
        this.activePage = '3';
    }
    // 去认证
    private openCert() {
        this.activePage = '4';
        // this.$router.push({ path: "/company/Certification",
        // query: { isBack: '0', isCert: '1' }}).catch((err) => err);
    }
    // 刷新体验列表
    private remoteExperience() {
        const experienceCard: any = this.$refs.experienceCard;
        experienceCard.initData();
    }
}
