
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';

@Component({
    name: "Proposal",
})
export default class Proposal extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: false,
    }) private optionsData!: boolean; // 接收父组件传过来的值
    private caption: string = "改进意见";
    private formTab: any = {

    };
    private optionsTab: any = [];
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('optionsData') // 监听父组件传过来的值
    private optionsDataFun(value: object) {
        this.optionsTab = JSON.parse(JSON.stringify(value));
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        this.closeTodo(num); // 给父组件传值
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        const elForm = this.$refs.formRef! as ElForm;
        elForm.resetFields();
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
}
