
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import MyCard from "../../../person/home/component/MyCard.vue";
import ApplyDialog from "@/components/RetainInfoDialog/ApplyDialog.vue";
import PerfectDialog from "@/components/RetainInfoDialog/PerfectDialog.vue";
import OpinionDialog from "@/components/RetainInfoDialog/OpinionDialog.vue";
import ConfirmDialog from "@/components/RetainInfoDialog/ConfirmDialog.vue";

@Component({
    name: "ExperienceList",
    components: {
        MyCard,
        ApplyDialog,
        PerfectDialog,
        OpinionDialog,
        ConfirmDialog,
    },
})
export default class ExperienceList extends Vue {
    @Prop(Number) private certStatus!: number;
    @Prop(Number) private screenWidth!: number;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private productList: any = [];
    private proList: any = [];
    private applyVisible: boolean = false;
    private perfectVisible: boolean = false;
    private opinionVisible: boolean = false;
    private showMore: boolean = false;
    private confirmFlg: boolean = false;
    private confirmMsg: any = '';
    private confirmTitle: any = null;
    private closeTitle: any = null;
    // 更多试用产品
    @Emit('moreTryProd')
    private moreTryProd() {
        return '/v2/try/uc/apply/index.htm';
    }
    @Emit('buyService')
    private buyService(detailUrl: any) {
        return detailUrl;
    }
    private created() {
        this.initData();
    }
    // 初始化加载数据
    private initData() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/systems/experiences_trials')
        .then((res: any) => {
            this.productList = res;
            this.proList = res.map((item: any) => ({
                id: item.mcId,
                name: item.name,
            }));
        });
    }
    // 建议反馈
    private openOpinion() {
        this.opinionVisible = true;
    }
    private closeOpinion(num: number) {
        this.opinionVisible = false;
    }
    // 立即体验
    private experienceNow(row: any) {
        this.perfectVisible = true;
    }
    private closeExperienceNow(num: number) {
        this.perfectVisible = false;
    }
    // 申请试用
    private applyTrial(row: any) {
        // 判断是否重复提交
        this.$message({
            message: '重复提交，您提交的试用申请正在处理中，请勿重复提交',
            type: 'warning',
        });
        this.applyVisible = true;
    }
    private closeApplyTrial(num: number) {
        this.applyVisible = false;
    }
    // 继续使用
    private async openUrl(url: string) {
        if (await this.$isLogin()) {
            window.open(url);
        } else {
            this.$alert('登录状态已过期，请重新登录', '提示', {
                showClose: false,
                confirmButtonText: '重新登录',
                callback: (action: any) => {
                    // 清除各种缓存数据
                    localStorage.clear();
                    // 跳转到登录页面
                    this.$router.push("/login").catch((err) => err);
                },
            });
        }
    }
    // 立即购买
    private buyNow(row: any) {
        if (this.certStatus === 2) {    // 判断企业是否已认证；certStatus：2 =》已认证
            // window.open(`${this.baseUrlIframe}/v2/goods/detail.htm?goodsId=${row.id}`);
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            window.open(`${baseUrl}/goods/detail?goodsId=${row.id}&active=2`);
        } else if (this.certStatus === 0) { // certStatus：0 =》未认证
            this.confirmFlg = true;
            this.confirmMsg = '抱歉，暂不支持未认证的企业用户购买产品，请先完成企业实名认证。';
            this.confirmTitle = '前往认证';
        } else if (this.certStatus === 1) { // certStatus：1 =》审核中
            this.confirmFlg = true;
            this.confirmMsg = '您当前提交的实名认证正在审核中，预计将1-3个工作日完成。';
            this.confirmTitle = '认证审核中';
        } else if (this.certStatus === -1) { // certStatus：-1 =》认证不通过
            this.confirmFlg = true;
            this.confirmMsg = '您当前提交的实名认证未通过审核，请重新提交申请。';
            this.confirmTitle = '重新提交';
        }
    }
    private confirmCloseMsg(val: any) {
        this.confirmFlg = false;
        if (val) {
            this.$router.push({
                path: "/company/Certification",
                query: { isBack: '0', isCert: '1' },
            }).catch((err) => err);
        }
    }
    // 更多体验产品
    private moreExperience(url: string) {
        window.open(`${this.baseUrlIframe}/v1/main/learn/index.htm`);
    }
}
