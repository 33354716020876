
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';

@Component({
    name: "RenewalList",
})
export default class RenewalList extends Vue {
    private inviteTab: any = {};
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    private tableData: any = [];
    @Watch('exitFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.initData();
        }
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    @Emit('openUrl')
    private openUrl(detailUrl: string) {
        return detailUrl;
    }
    // 续费
    @Emit('renewal')
    private renewal(row: any) {
        return row;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(0); // 给父组件传值
    }
    // 初始化加载数据
    private initData() {
        this.$httpService.getData({renewal: 1}, '/apiProxy/api/frontend/company/systems/purchased')
        .then((res: any) => {
            this.tableData = res;
        });
    }
    // 查看详情
    private checkUrl(detailUrl: string) {
        if (detailUrl) {
            this.closeTodo(0);
            this.openUrl(detailUrl);
        }
    }
}
