
import { Component, Emit, Vue } from 'vue-property-decorator';
import MyCard from "../../../person/home/component/MyCard.vue";

@Component({
    name: "ServiceCard",
    components: {
        MyCard,
    },
})
export default class ServiceCard extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private tableData: any = [];
    @Emit('openUrl')
    private openUrl(detailUrl: string) {
        return detailUrl;
    }
    private created() {
        this.initData();
    }
    // 初始化加载数据
    private initData() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/company/systems/purchased/renewable')
        .then((res: any) => {
            this.tableData = res;
        });
    }
    // 查看详情
    private checkUrl(detailUrl: string) {
        if (detailUrl) {
            // this.openUrl(detailUrl);
            this.$router.push({ path: "/company/resource",
            query: { from: 'home' }}).catch((err) => err);
        }
    }
}
